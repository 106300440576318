import React from "react";
import { useAppDispatch } from "../../store/hooks";
import { userActions } from "../../store/user";
import { CheckOutlined } from "@ant-design/icons";

const DirectPurchasePage: React.FC = () => {
  const directPurchaseConditions = {
    minimumPurchase: {
      minAmoutn: 500,
      description: [
        "Best value for purchases",
        "Secure coin transfers guaranteed",
        "Safe and fast wallet delivery",
        "Maximize your investment today",
      ],
    },
    stacking: {
      minAmoutn: 1200,
      description: [
        "Minimum $1200 investment required",
        "Unlock exclusive rewards with 150 coins!",
        "Invest for long-term growth",
        "Harvest incredible benefits over time",
      ],
    },
  };
  const dispatch = useAppDispatch();
  return (
    <div className="features" id="direct-purchase">
      <div className="header-text">Direct Purchase</div>
      <div className="direct-purchase-page">
        <div className="purchase-card">
          <h2>{`=<$500`}</h2>
          <div className="card-info">
            {directPurchaseConditions.minimumPurchase.description.map(
              (desc) => (
                <p>
                  <CheckOutlined />
                  {desc}
                </p>
              )
            )}
          </div>
          <button
            onClick={() => dispatch(userActions.signInModal({ visible: true }))}
          >
            Buy Now
          </button>
        </div>
        <div className="purchase-card">
          <h2>Stacking</h2>
          <div className="card-info">
            {directPurchaseConditions.stacking.description.map(
              (desc) => (
                <p>
                  <CheckOutlined />
                  {desc}
                </p>
              )
            )}
          </div>
          <button
            onClick={() => dispatch(userActions.signInModal({ visible: true }))}
          >
            Buy Now
          </button>
        </div>
      </div>
    </div>
  );
};

export default DirectPurchasePage;
