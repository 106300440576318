import React, { useEffect, useState } from "react";
import Modal from "antd/lib/modal/Modal";
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  Typography,
  notification,
  Card
} from "antd";
import { DollarOutlined, ShopOutlined } from "@ant-design/icons";
import HAVETOApi from "../../services/api/now_payments/api";
import { CoinsInterface } from "../../services/api/now_payments/types";
import giftIcon from "../../assets/gift-icon.png";

interface BuyCoinModalInterface {
  visible: boolean;
  onClose: () => void;
}
interface BuyCoinFormInterface {
  amount: number;
  crypto_coin: string;
}

const BuyHavetoCoinModal = ({ visible, onClose }: BuyCoinModalInterface) => {
  const [appForm] = Form.useForm();
  const [loader, setLoader] = useState<boolean>(false);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [paymentGatewayType, setPaymentGatewayType] = useState("copperx")
  const [paymentResponse, setPaymentResponse] = useState<any>({});
  const [coins, setCoins] = useState<CoinsInterface[]>([]);
  const [iscopy, setIscopy] = useState<boolean>(false);
  const minAmounts: { [key: string]: number } = {
    USDC: 500,
    USDT: 500,
    ETH: 0.28,
    BTC: 0.0061,
    DAI: 500,
    BNB: 0.83,
    MATIC: 2436.65,
    BUSD: 499.54,
    SOL: 3.97,
    TCN:0.01,
  };

  useEffect(() => {
    if (visible) {
      setIsVisible(false);
      appForm.resetFields();
      appForm.setFieldsValue({ crypto_coin: "USDC", amount: minAmounts.USDC });
      getCoins();
    }
  }, [visible]);

  const onFinish = (values: BuyCoinFormInterface) => {
    setLoader(true);
    const paymemntAPI=paymentGatewayType === "copperx"?HAVETOApi.makeCoperXPayment:HAVETOApi.makeCoinRemitterPayment
    paymemntAPI({
      price_amount: values.amount,
      crypto_coin: values.crypto_coin,
    })
      .then((result) => {
        setLoader(false);
        setPaymentResponse(result.paymentResponse)
        if(result?.paymentResponse?.url){
          window.location.href = result.paymentResponse.url;
          setIsVisible(true);
        }else{
          if (result?.paymentResponse.payment_url) {
            window.location.href = result.paymentResponse.payment_url;
            handleCancel();
          }
        }
      })
      .catch((error) => {
        setLoader(false);
        notification.error({ message: error?.detail.split(":")[1] });
      });
  };

  const handleCancel = () => {
    appForm.resetFields();
    setIsVisible(false);
    onClose();
  };

  const getCoins = () => {
    if (coins?.length === 0) {
      HAVETOApi.getCryptoCoin()
        .then((result) => {
          setCoins(result.coins);
        })
        .catch((error) => {
          setLoader(false);
          notification.error({ message: error?.detail.split(":")[1] });
        });
    }
  };

  const handleCoinChange = (value: keyof typeof minAmounts) => {
    appForm.setFieldsValue({ amount: minAmounts[value] });
    const selectedCoin:any[]=coins.filter(coin => coin.coin===value);
    setPaymentGatewayType(selectedCoin[0].payment_gateway)
  };

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(e.target.value);
  };

  return (
    <Modal
      style={{ background: "white" }}
      visible={visible}
      title={"Haveto Payments"}
      centered={true}
      onCancel={handleCancel}
      footer={null}
    >
      <Form form={appForm} layout="vertical" onFinish={onFinish}>
        <Row gutter={[0, 10]} align="middle" justify="center">
          <Col span={24}>
            <Form.Item
              label={
                <Typography.Text className="text-black-color">
                  Amount
                </Typography.Text>
              }
              name="amount"
              validateTrigger="onChange"
              rules={[
                {
                  required: true,
                  message: "Enter amount",
                },
                {
                  validator: (_, value) => {
                    if (
                      value >= minAmounts[appForm.getFieldValue("crypto_coin")]
                    ) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject(
                        new Error(
                          `Minimum amount is ${
                            minAmounts[appForm.getFieldValue("crypto_coin")]
                          }`
                        )
                      );
                    }
                  },
                },
              ]}
            >
              <Input
                disabled={isVisible}
                prefix={<DollarOutlined />}
                type="number"
                className="email-input"
                placeholder="Enter Amount"
                onChange={handleAmountChange}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label={
                <Typography.Text className="text-black-color">
                  Coin
                </Typography.Text>
              }
              name="crypto_coin"
              rules={[
                {
                  required: true,
                  message: "Select Coin",
                },
              ]}
            >
              <Select
                onClick={getCoins}
                onChange={handleCoinChange}
                disabled={isVisible}
                className="currency-option-select"
                defaultValue="USDC"
              >
                {coins?.map((coin) => (
                  <Select.Option key={coin.coin} value={coin.coin}>
                    {coin.coin}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label={" "}>
              <Button
                className={"buy-htc-coin"}
                onClick={appForm.submit}
                loading={loader}
                icon={<ShopOutlined />}
              >
                Buy Coin
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default BuyHavetoCoinModal;
