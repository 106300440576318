import coinTableLevelDistribution from "../../assets/websiteIcon/cointable_level_distribution.svg";
import coinTableSalesDistribution from "../../assets/websiteIcon/cointable_sale_distribution.svg";
import { Col, Image, Row, } from "antd";

const CoinTable = () => {

  return (
    <div className="features" id={"coin-table"}>
      <div className="header-text">Coin Table</div>
      <Row justify={"center"} align={"middle"}>
        <Col span={24} className="text-center">
        <Image src={coinTableLevelDistribution} preview={false} />
        <Image src={coinTableSalesDistribution} preview={false} />
        </Col>
      </Row>
    </div>
  );
};

export default CoinTable;
